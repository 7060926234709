import { graphql, useStaticQuery } from "gatsby";
import React from "react"

const Login = ({ className }) => {
    const { site } = useStaticQuery(
        graphql`
        query {
          site {
            siteMetadata {
                  externalReferences {
                    customerPortal
                  }
              }
          }
        }
      `
    );
    return (<a href={site.siteMetadata.externalReferences.customerPortal} className={"button " + className}>Sign in</a>)
}

export default Login