import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import APILink from "./api_link"
import DidimoLogo from "./didimo_logo"
import Login from "./login"
import MainMenuIcon from "./main_menu_icon"
import SearchIcon from "./search_icon"

const Header = ({ siteTitle, onShowMainMenu }) => (
  <header
    id="main-header"
  >
    <MainMenuIcon onClick={onShowMainMenu} />
    <div
      className="portal-identification"
            style={{width:307, height:20}}
    ><Link
      to="/"
      style={{
        textDecoration: `none`,
      }}
    >
    <div style={{width:307, height:20}}>
        <DidimoLogo className="logo" />
        <p
          className="portal-name" >{siteTitle}</p>
    </div>
      </Link>
    </div>
    <div className={"important-references"}>
      <APILink className={"first-item"} />
      <Login />
    </div>
    <SearchIcon />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  onShowMainMenu: PropTypes.func,
}

Header.defaultProps = {
  siteTitle: ``,
  onShowMainMenu: () => { }
}

export default Header
