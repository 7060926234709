import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";


const MenuSection = ({ currentPagePath, nodes, presetItems }) => {

var found = false;
var activeParentPath = "";

//search for active child so we can keep the active parent open, and don't display child items of unactive root items in the root
for (var i = 0; i<nodes.length;i++)
{
    if(nodes[i].path == currentPagePath)
    {
        activeParentPath = nodes[i].path;
        found = true;
        break;
    }
    for (var j = 0; j<nodes[i].childNodes.length;j++)
    {
        if(nodes[i].childNodes[j].path == currentPagePath)
        {
            activeParentPath = nodes[i].path;
            found = true;
            break;
        }
    }
    if(found)
        break;
}

    return (
        <ul>
            {presetItems}
            {nodes.map((node) => (
                <li>
                    <Link to={node.path} className={currentPagePath == node.path ? "selected" : ""}>{node.title}</Link>
                    {((currentPagePath == node.path || activeParentPath == node.path)  ) && node.childNodes && node.childNodes.length > 0 && <MenuSection currentPagePath={currentPagePath} nodes={node.childNodes} />}
                    </li>
            ))}
        </ul>
    )
};

const MainMenu = ({ currentPagePath, isVisible, structure }) => {
    const { site } = useStaticQuery(
        graphql`
          query {
            site {
                siteMetadata {
                    externalReferences {
                        apiDocs
                    }
                }
            }
          }
        `
    )

    return (<nav id="main-menu" className={`${isVisible ? "show" : ""}`}>
        <MenuSection nodes={structure} currentPagePath={currentPagePath} presetItems={
            (<>
                <li><Link to={"/"} className={"main-menu-home"}>Home</Link></li>
                <li><a href={site.siteMetadata.externalReferences.apiDocs}>API</a></li>
            </>)
        } />
    </nav>
    )
}

export default MainMenu;