/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import MainMenu from "./main_menu"
import "./layout.css"

const Layout = ({ currentPath, mainMenuStructure, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isMainMenuVisible, setMainMenuVisible] = useState(false);

  const showMainMenuHandler = (event) => {
    setMainMenuVisible(!isMainMenuVisible);
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} onShowMainMenu={showMainMenuHandler} />
      <div
        id="center-container"
        style={{
          /*padding: `0 1.0875rem 1.45rem`,*/
          display: `relative`
        }}
      >
        <MainMenu isVisible={isMainMenuVisible} structure={mainMenuStructure} currentPagePath={currentPath} />
        <main id="content">{children}</main>
      </div>
      <footer>
        © {new Date().getFullYear()},
          {` `}
        <a href="https://didimo.co">Didimo</a>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
