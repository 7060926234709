import { useStaticQuery, graphql } from "gatsby";
import React from "react"

const APILink = ({ className }) => {
    const { site } = useStaticQuery(
        graphql`
        query {
          site {
            siteMetadata {
                  externalReferences {
                      apiDocs
                  }
              }
          }
        }
      `
    );

    return (<a href={site.siteMetadata.externalReferences.apiDocs} className={"button " + className}>API</a>)
}

export default APILink